/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/22/20, 10:57 AM
 *
 */

.logosvg {
    /* box-shadow: 3px 3px 3px #ccc; */
    -webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .3));
  filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .35));
  /* Similar syntax to box-shadow */
}

.dot {
    /* stroke-width: 30px;
    stroke: red; */
}

.logo-box {
    display: block;
    /*padding: 5px 20px;*/
    padding: 0 !important;
    margin: 0;
    text-align: center;
}

.logo-box a.logo-text {
    color: #532ac4;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.1em;
    font-size: 24px;

    font-family: 'Montserrat', sans-serif;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.dark-theme .logo-box a.logo-text {
    color: #89ffb8;
    transition: all 1.6s ease-in-out;
}

.logo-box a#sidebar-state,
.logo-box a#sidebar-close {
    float: right;
    color: #7D7D83;
    line-height: 19px;
    margin: 8px 0;
    opacity: .8;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

h5.brand-text {
    font-family: 'Kanit', sans-serif;
    font-size: 0.9em;
    font-weight: 200;
    letter-spacing: 0.5em;
}

.logo-box a#sidebar-state i,
.logo-box a#sidebar-close i {
    font-size: 19px;
    vertical-align: text-top;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
}

.logo-box a#sidebar-state:hover,
.logo-box a#sidebar-close:hover {
    color: #532ac4;
}

@media (max-width: 1199px) {
    .logo-box a#sidebar-state {
        visibility: hidden;
        width: 0;
        opacity: 0;
    }
}

@media (min-width: 1200px) {
    .logo-box a#sidebar-state i.compact-sidebar-icon,
    .compact-sidebar .logo-box a#sidebar-state i:not(.compact-sidebar-icon) {
        visibility: hidden;
        width: 0;
        opacity: 0;
    }

    .compact-sidebar .logo-box a#sidebar-state i.compact-sidebar-icon {
        visibility: visible;
        opacity: 1;
        width: auto;
    }

    #sidebar-close {
        visibility: hidden;
        width: 0;
        opacity: 0;
    }
}

.logo-box a:hover {
    color: #38a67c;
    transition: all .6s ease-in-out;
}
.dark-theme .logo-box a:hover {
    color: #745cff;
    transition: all .6s ease-in-out;
}

.loading {
    background: rgba(255,0,0,1);
    border-radius: 100%;
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    /* transition: all 5s; */
    opacity: 0%;
}
